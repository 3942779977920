<!--
 * @Descripttion: 404页面
 * @version: 1.0
 * @Author: Niklaus
 * @Date: 2021-08-23 08:59:04
-->
<template>
  <div class="not-found">
    <p>温馨提示：您访问的竞价供应商端进行了系统升级，请复制下面网址在浏览器中打开，请使用用户名和密码登录。</p>
    <p class="url">{{ url }}</p>
    <input type="text" readonly :value="url" id="input">
    <el-button type="primary" size="default" @click="handleCopy">复制</el-button>

  </div>
</template>

<script>
export default {
  data(){
    return{
      url:'http://zgclient.bidsoon.cn/login'
    }
  },
  methods:{
    handleCopy(){
      let input = document.getElementById('input');
      console.log(input);
      input.select();
      document.execCommand("Copy")
      this.$message.success('复制成功!')
    }
  }
}
</script>

<style scoped>
  .not-found {
    width: 100%;
    height: 100vh;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
    transform: translateY(-20vh);
  }
  .not-found p {
    padding: 15px;
    font-size: 1.3em;
  }
  .not-found .url {
    font-size: 1.2em;
    color: #0984e3;
    font-weight: 600;
  }
  .not-found button {
    margin-top: 50px;
  }
  #input {
    opacity: 0;
  }
</style>
